import axiosTransport from './axios';
import { setFormErrors, clearFormErrors } from './';
import { loadCheckinUsers } from './checkins';
import { setPaymentError } from './gyms';
import { setStatusMessage, STATUS_TYPE_ERROR, STATUS_TYPE_SUCCESS } from './forms';

const CREATE_USER_API_URL = '/users';

export function createUser(firstName, lastName, email, password){
    return function(dispatch){

        return axiosTransport.post(CREATE_USER_API_URL, {
            first_name: firstName,
            last_name: lastName,
            email,
            password
        }).then((response) => {
            dispatch(setFormErrors(null));
            return dispatch(setUserCreated(response.data));
        }).catch((err) => {
            console.log(err);
            if(err.response.statusCode > 200){
                //set error message
            }

            return dispatch(setFormErrors(err.response.data));

        });
        
    };
}

export const USER_CREATED = 'USER_CREATED';

export function setUserCreated(user){
    return {
        type: USER_CREATED,
        user
    };
};

export const TAKE_PAYMENT = 'TAKE_PAYMENT';

export function takePayment(){
    return {
        type: TAKE_PAYMENT
    };
}

export const EXIT_TAKE_PAYMENT = 'EXIT_TAKE_PAYMENT';

export function exitTakePayment(){
    return function(dispatch){

        dispatch(setPaymentError(null));
        dispatch(clearFormErrors());

        return dispatch({
            type: EXIT_TAKE_PAYMENT
        });
    }
}

export const MANUAL_CHECKIN = 'MANUAL_CHECKIN';

export function manualCheckin(){
    return {
        type: MANUAL_CHECKIN
    };
}

export const EXIT_MANUAL_CHECKIN = 'EXIT_MANUAL_CHECKIN';

export function exitManualCheckin(){
    return function(dispatch){

        dispatch(loadCheckinUsers(null));

        return dispatch({
            type: EXIT_MANUAL_CHECKIN
        });
    };
}

export const ASSIGN_DISCOUNT = 'ASSIGN_DISCOUNT';

export function assignDiscount(){
    return {
        type: ASSIGN_DISCOUNT
    };
}

export const EXIT_ASSIGN_DISCOUNT = 'EXIT_ASSIGN_DISCOUNT';

export function exitAssignDiscount(){
    return {
        type: EXIT_ASSIGN_DISCOUNT
    };
}

///

export const TRANSFER_SUCCESS = 'TRANSFER_SUCCESS';
export const TRANSFER_FAILURE = 'TRANSFER_FAILURE';

const TRANSFER_FROM_GUEST_API_URL = '/users/transfer/from-guest';

export function transferFromGuestToMember(guestUserId, userId){

    let payload = {
        guest_user_id: guestUserId,
        user_id: userId,
    };

    return function(dispatch){
        return axiosTransport.patch(TRANSFER_FROM_GUEST_API_URL, payload).then(({data}) => {
            dispatch(setStatusMessage('Guest merged with member account', STATUS_TYPE_SUCCESS));
            dispatch(removeGuestFromMembers(guestUserId));
            return dispatch(setTransferFromGuestStatus(TRANSFER_SUCCESS));
        }).catch((err) => {
            dispatch(setStatusMessage('Problem merging guest with member account', STATUS_TYPE_ERROR));
            return dispatch(setTransferFromGuestStatus(TRANSFER_FAILURE));
        });
    }
}

export const SET_TRANSFER_FROM_GUEST_STATUS = Symbol('SET_TRANSFER_FROM_GUEST_STATUS');

export function setTransferFromGuestStatus(status){
    return {
        type: SET_TRANSFER_FROM_GUEST_STATUS,
        status
    };
}

export const RESET_TRANSFER_STATUS = Symbol('RESET_TRANSFER_STATUS');

export function resetTransferStatus(){
    return {
        type: RESET_TRANSFER_STATUS
    };
}

const TRANSFER_TO_GUEST_API_URL = '/users/transfer/to-guest';

const SEARCH_FULL_MEMBER_API_URL = '/users/search';

export function searchForFullMembers(name = ''){

    let payload = {
        name,
        member_type: 'member',
    };

    return function(dispatch){

        if(name.trim().length === 0){
            return dispatch(setStatusMessage(`You need to enter a name!`, STATUS_TYPE_ERROR));
        }

        return axiosTransport.get(SEARCH_FULL_MEMBER_API_URL, { params: payload }).then(({data}) => {
            return dispatch(loadFullMembers(data));
        }).catch(err => {

            dispatch(setStatusMessage(`No members found matching "${name}"`, STATUS_TYPE_ERROR));
            return dispatch(loadFullMembers(null));
        });
    };
}

export const LOAD_FULL_MEMBERS = Symbol('LOAD_REAL_MEMBERS');

export function loadFullMembers(members){

    return {
        type: LOAD_FULL_MEMBERS,
        members
    };
}

export const REMOVE_GUEST_FROM_MEMBERS = Symbol('REMOVE_GUEST_FROM_MEMBERS');

export function removeGuestFromMembers(guestId){
    return {
        type: REMOVE_GUEST_FROM_MEMBERS,
        guestId
    };
}
