import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import ThunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import axiosTransport from './actions/axios';

const api = axiosTransport;
const createStore = () => reduxCreateStore(rootReducer, applyMiddleware(ThunkMiddleware.withExtraArgument(api)));
const store = createStore();

axiosTransport.interceptors.request.use(function (config) {
    const {
        currentGym
    } = store.getState();

    if(currentGym){
        // config.headers.Authorization =  token;
        config.headers['X-Gym-Hash'] = currentGym.gym_hash;
    }
    
    config.withCredentials = true;
    
    return config;
});

export default ({element}) => (
    <Provider store={store}>{element}</Provider>
);