export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';

export function setFormErrors(errors){
    return {
        type: SET_FORM_ERRORS,
        errors
    };
};

export const CLEAR_FORM_ERRORS = 'CLEAR_FORM_ERRORS';

export function clearFormErrors(){
    return {
        type: CLEAR_FORM_ERRORS
    };
};

export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_ERROR = 'FORM_ERROR';
export const FORM_STATUS = 'FORM_STATUS';

export function setFormStatus(status){
    return {
        type: FORM_STATUS,
        status
    };
}

export const CLEAR_FORM_STATUS = 'CLEAR_FORM_STATUS';

export function clearFormStatus(){
    return {
        type: CLEAR_FORM_STATUS
    };
}

export const SET_STATUS_MESSAGE = 'SET_STATUS_MESSAGE';
export const STATUS_TYPE_SUCCESS = 'success';
export const STATUS_TYPE_ERROR = 'error';

export function setStatusMessage(message, messageType){
    return function(dispatch){

        if(message && messageType === STATUS_TYPE_SUCCESS){
            setTimeout(() => {
                dispatch(setStatusMessage(null, null));
            }, 4000);
        }

        return dispatch({
            type: SET_STATUS_MESSAGE,
            message,
            messageType
        });
    };
};