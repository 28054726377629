import { exitManualCheckin } from './users';
import { setStatusMessage, STATUS_TYPE_SUCCESS } from './forms';

const GET_CHECKINS_API = '/gyms/checkins/{GYM_HASH}/{DATE}';

export function getCheckins(gymHash, date){
    const simpleDate = date.format('YYYY-MM-DD');
    return async function (dispatch, getState, api) {
        return await api.get(GET_CHECKINS_API.replace('{GYM_HASH}', gymHash).replace('{DATE}', simpleDate)).then((response) => response.data).catch(err => err).then((data) => {
            return dispatch(loadCheckins(data));
        });
    };
}

export const LOAD_CHECKINS = 'LOAD_CHECKINS';

export function loadCheckins(checkins){
    return {
        type: LOAD_CHECKINS,
        checkins,
    };
};

export const ADD_CHECKIN = 'ADD_CHECKIN';

export function addCheckin(checkin){
    return function(dispatch){
        setTimeout(() => {
            dispatch(clearLastCheckin());
        }, 4000);

        return dispatch({
            type: ADD_CHECKIN,
            checkin
        });
    };
};

export const CLEAR_LAST_CHECKIN = 'CLEAR_LAST_CHECKIN';

export function clearLastCheckin(){
    return {
        type: CLEAR_LAST_CHECKIN
    };
}

export const SELECTED_CHECKIN = 'SELECTED_CHECKIN';

export function selectCheckin(checkin){
    return {
        type: SELECTED_CHECKIN,
        checkin
    };
}

export const CLEAR_CHECKINS = 'CLEAR_CHECKINS';

export function clearCheckins(){
    return {
        type: CLEAR_CHECKINS
    };
}

const SEARCH_USER_API_URL = '/gyms/search-users';

export function searchForUser(name, email){

    let payload = {
        name, 
        email
    };

    return function(dispatch, getState, api){
        return api.get(SEARCH_USER_API_URL, { params: payload }).then(({data}) => {
            dispatch(setErrorMessage(null));
            return dispatch(loadCheckinUsers(data));
        }).catch(err => {

            let { data } = err.response

            if(data && data.message){
                dispatch(setErrorMessage(data.message));
            }

            return dispatch(loadCheckinUsers(null));
        });
    };
}

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

export function setErrorMessage(message){
    return {
        type: SET_ERROR_MESSAGE,
        message
    };
}

export const LOAD_CHECKIN_USERS = 'LOAD_CHECKIN_USERS';

export function loadCheckinUsers(users){
    return {
        type: LOAD_CHECKIN_USERS,
        users
    };
}

const TRACK_USER_API_URL = '/gyms/track-user';

export function trackUser(userId){
    
    let payload = {
        user_id: userId
    };

    return function(dispatch, getState, api){
        return api.post(TRACK_USER_API_URL, payload).then(() => {
            dispatch(setStatusMessage('Manual check-in created.', STATUS_TYPE_SUCCESS));
            return dispatch(exitManualCheckin());
        }).catch();
    };
}

export const DELETE_CHECKIN = 'DELETE_CHECKIN';

export function deleteCheckin(){
    return {
        type: DELETE_CHECKIN
    };
}

export const EXIT_DELETE_CHECKIN = 'EXIT_DELETE_CHECKIN';

export function exitDeleteCheckin(){
    return {
        type: EXIT_DELETE_CHECKIN
    };
}

const DELETE_CHECKIN_API_URL = '/checkins/{CHECKIN_ID}';

export function deleteUserCheckin(checkin){
    return function(dispatch, getState, api){
        return api.delete(DELETE_CHECKIN_API_URL.replace('{CHECKIN_ID}', checkin.id)).then(({data}) => {
            dispatch(exitDeleteCheckin());
            return dispatch(removeCheckinFromList(checkin.id));
        }).catch(err => {

        });
    };
}

export const REMOVE_CHECKIN = 'REMOVE_CHECKIN';

export function removeCheckinFromList(id){
    return {
        type: REMOVE_CHECKIN,
        id
    };
}