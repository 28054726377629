import {
    SET_FORM_ERRORS, 
    FORM_SUCCESS, 
    FORM_STATUS, 
    FORM_ERROR, 
    CLEAR_FORM_STATUS, 
    CLEAR_FORM_ERRORS, 
    SET_ERROR_MESSAGE, 
    EXIT_ADD_GUEST_USER, 
    EXIT_ASSIGN_DISCOUNT, 
    EXIT_MANUAL_CHECKIN, 
    EXIT_TAKE_PAYMENT, 
    EXIT_DRAFT_MESSAGE, 
    SET_STATUS_MESSAGE
} from '../actions';

export function formSuccess(state = null, action){

    if(FORM_STATUS === action.type && FORM_SUCCESS === action.status){
        return true;
    }

    if(FORM_STATUS === action.type && FORM_ERROR === action.status){
        return false;
    }

    if(CLEAR_FORM_STATUS === action.type){
        return null;
    }

    return state;
}

export function formErrors(state = {}, action){
    
    if(action.type === SET_FORM_ERRORS && action.errors){

        let { errors } = action;

        if(errors.details){
            let errorsByKey = {};

            errors.details.map((error) => {
                let { context, message } = error;

                if(context){
                    errorsByKey[context.key] = message;
                }

                return error;

            });

            return errorsByKey;
        }

        return {};
    }

    if(CLEAR_FORM_ERRORS === action.type || (action.type === SET_FORM_ERRORS && !action.errors)){
        return {};
    }

    const exitActions = [
        EXIT_ADD_GUEST_USER,
        EXIT_ASSIGN_DISCOUNT,
        EXIT_MANUAL_CHECKIN,
        EXIT_TAKE_PAYMENT,
        EXIT_DRAFT_MESSAGE
    ]

    if(exitActions.includes(action.type)) return {};

    return state;
}

export function errorMessage(state = null, action){

    if(SET_ERROR_MESSAGE === action.type) return action.message || null;

    return state;
}

export function currentStatusMessage(state = null, action){
    
    if(SET_STATUS_MESSAGE === action.type && action.message) return { message: action.message, type: action.messageType };

    if(SET_STATUS_MESSAGE === action.type && !action.message) return null;

    return state;
}