export const CHANGE_CHECKIN_DATE = 'CHANGE_CHECKIN_DATE';

export function changeCheckinDate() {
  return function (dispatch) {
    return dispatch(changeDate(CHANGE_CHECKIN_DATE));
  }
}

export const CHANGE_PAYMENT_DATE = 'CHANGE_PAYMENT_DATE';

export function changePaymentDate() {
  return function (dispatch) {
    return dispatch(changeDate(CHANGE_PAYMENT_DATE));
  }
}

export const CHANGE_DATE = 'CHANGE_DATE';

export function changeDate(target) {
  return {
    type: CHANGE_DATE,
    target,
    open: true
  };
}

export const EXIT_CHANGE_DATE = 'EXIT_CHANGE_DATE';

export function exitChangeDate() {
  return {
    type: EXIT_CHANGE_DATE
  };
}