import { 
    USER_LOGOUT, 
    LOAD_PAYMENTS,
    DELETE_PAYMENT,
    EXIT_DELETE_PAYMENT,
    REMOVE_PAYMENT,
    CLEAR_PAYMENTS
} from "../actions";

export function payments(state = null, action){

    if([USER_LOGOUT, CLEAR_PAYMENTS].includes(action.type)){
        return null;
    }
    
    if(LOAD_PAYMENTS === action.type) return action.payments;

    if(REMOVE_PAYMENT === action.type && state){

        let newState = Object.assign({}, state)

        newState.payments = newState.payments.map((payment) => {
            if(payment.id !== action.id){
                return payment;
            }

            return undefined;
        }).filter(payment => payment !== undefined)

        return newState;
    }

    return state;
}

export function isDeletingPayment(state = false, action){

    if(DELETE_PAYMENT === action.type) return true;
    
    if(EXIT_DELETE_PAYMENT === action.type) return false;
    
    return state;
}