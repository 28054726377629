import { EXIT_CHANGE_DATE } from '../actions';

const CHANGE_DATE = 'CHANGE_DATE';

const defaultState = {
  target: CHANGE_DATE,
  open: false,
};

export function isChangingDate(state = defaultState, action) {
  
  const { target, open } = action;

  if (action.type === CHANGE_DATE) {
    return {
      target,
      open
    };
  }

  if (action.type === EXIT_CHANGE_DATE) return defaultState;

  return state;
}