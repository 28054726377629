import {
    WC_CONNECT,
    WC_DISCONNECT
} from '../actions';

export function socket(state = null, action){
    
    if(WC_CONNECT === action.type && action.socket){
        return action.socket;
    }

    if(WC_DISCONNECT === action.type){
        return null;
    }

    return state;
}