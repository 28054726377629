import {
    SET_GYMS,
    USER_LOGOUT,
    SET_CURRENT_GYM,
    LOAD_PLANS,
    ADD_GYM,
    LOAD_MEMBERS,
    LOAD_MEMBERSHIP_TYPES,
    LOAD_DISCOUNTS,
    SET_USER,
    REMOVE_GUEST_FROM_MEMBERS
} from '../actions';

const windowGlobal = typeof global.window !== undefined && global.window ? global.window : null;

export function plans(state = [], action){

    if(action.type === LOAD_PLANS && (action.plans && action.plans.length)){
        return action.plans;
    }

    return state;
}

export function currentGyms(state = [], action){

    if(!windowGlobal){
        return state;
    }

    let storage = windowGlobal.localStorage;

    if(state && !state.length){
        let gyms = storage.getItem('gyms');
        
        if(gyms){
            return JSON.parse(gyms);
        }
        
    }

    if(action.type === USER_LOGOUT){
        storage.clear();
        return [];
    }
    
    if([SET_GYMS, ADD_GYM].includes(action.type)){

        let gyms = (action.gyms) ? action.gyms : [...state, action.gym];

        if(gyms){
            storage.setItem('gyms', JSON.stringify(gyms));
        }

        return gyms;
    }

    return state;
}

export function currentGym(state = null, action){
    
    if(!windowGlobal){
        return state;
    }

    let storage = windowGlobal.localStorage;

    if(state === null){
        let gym = storage.getItem('currentGym');
        
        if(gym){
            return JSON.parse(gym);
        }
        
    }

    if(action.type === SET_CURRENT_GYM){

        if(action.gym){
            storage.setItem('currentGym', JSON.stringify(action.gym));
        }

        return action.gym;
    }

    if(action.type === USER_LOGOUT){
        storage.clear();
        return null;
    }

    if(SET_USER === action.type && !action.user){
        storage.clear();
        return null;
    }

    return state;
}

export function members(state = [], action){

    if(action.type === LOAD_MEMBERS && action.members){
        return action.members;
    }

    if(REMOVE_GUEST_FROM_MEMBERS === action.type){
        let newState = state.map((group) => {
            group.members = group.members.map((member) => {
                if(member.id !== action.guestId){
                    return member;
                }

                return undefined;
            }).filter(member => member !== undefined);

            return group;
        });

        return newState;
    }

    return state;
}

export function membershipTypes(state = [], action){

    if(LOAD_MEMBERSHIP_TYPES === action.type) return action.types || [];

    return state;
}

export function discounts(state = [], action){

    if(LOAD_DISCOUNTS === action.type) return action.discounts || [];

    return state;
}