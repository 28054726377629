import axiosTransport from "./axios";
import { setCurrentGym } from './';
import { setFormErrors } from './forms';

const AUTH_API_URL = '/users/verify';

export function verifyUser(email, password){
    return function(dispatch){

        let formData = new FormData();
        formData.set('email', email);
        formData.set('password', password);

        return axiosTransport.post(AUTH_API_URL, { email, password }).then((response) => {
            let { gyms, user } = response.data;

            if(gyms && gyms.length){
                dispatch(setGyms(gyms));
                dispatch(setCurrentGym(gyms[0]));
            }
            
            return dispatch(setUser(user));
        }).catch((err) => {
            if (err.response && err.response.data) {
                const { details } = err.response.data;

                if (details) {
                    dispatch(setFormErrors(err.response.data));
                }
            }

            return dispatch(setUser(null));

        });
        
    };
}

const LOGOUT_API_URL = '/users/logout';

export const USER_LOGOUT = 'USER_LOGOUT';

export function logoutUser(){
    return function(dispatch){
        return axiosTransport.post(LOGOUT_API_URL).then((response) => response).catch((err) => err).then((response) => {
            return dispatch({
                type: USER_LOGOUT
            });
        });
    };
}

const GET_USER_IDENTITY_API_URL = '/users/identity';

export function getUserIdentity(){
    return function(dispatch){
        return axiosTransport.get(GET_USER_IDENTITY_API_URL).then((response) => {
            return dispatch(setUser(response.data));
        }).catch((err) => {
            if(err.response && err.response.statusCode > 200){
                //set error message
            }

            return dispatch(setUser(null));

        });
    };
}

export const SET_USER = 'SET_USER';

export function setUser(user){

    return {
        type: SET_USER,
        user
    };
}

export const SET_GYMS = 'SET_GYMS';

export function setGyms(gyms){
    return {
        type: SET_GYMS,
        gyms
    };
}