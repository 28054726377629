import axiosTransport from "./axios";
import { setFormErrors } from './';
import { setFormStatus, FORM_SUCCESS, FORM_ERROR } from './forms';

export const DRAFT_MESSAGE = 'DRAFT_MESSAGE';

export function draftMessage(){
    return {
        type: DRAFT_MESSAGE
    };
}

export const EXIT_DRAFT_MESSAGE = 'EXIT_DRAFT_MESSAGE';

export function exitDraftMessage(){
    return function(dispatch){

        dispatch(loadNotification(null));

        return dispatch({
            type: EXIT_DRAFT_MESSAGE
        });
    }
}

const GET_NOTIFICATIONS_API_URL = '/notifications/{GYM_HASH}';

export function getNotifications(){
    return function(dispatch, getState){
        const state = getState();
        const gymHash = state.currentGym ? state.currentGym.gym_hash : null;

        return axiosTransport.get(GET_NOTIFICATIONS_API_URL.replace('{GYM_HASH}', gymHash)).then(({data}) => {
            return dispatch(loadNotifications(data));
        }).catch();
    };
}

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';

export function loadNotifications(notifications){
    return {
        type: LOAD_NOTIFICATIONS,
        notifications
    };
}

export const LOAD_NOTIFICATION = 'LOAD_NOTIFICATION';

export function loadNotification(notification){
    return {
        type: LOAD_NOTIFICATION,
        notification
    };
}

export function editNotification(notification){
    return function(dispatch){

        dispatch(loadNotification(notification));
        return dispatch(draftMessage());
    }
}

const DELETE_NOTIFICATION_API_URL = '/notifications/{NOTIFICATION_ID}';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export function deleteNotification(notification){
    return function(dispatch, getState){

        const state = getState();

        const data = {
            gym_hash: state.currentGym.gym_hash
        };
        
        return axiosTransport.delete(DELETE_NOTIFICATION_API_URL.replace('{NOTIFICATION_ID}', notification.id), {data}).then(() => {
            return dispatch({
                type: DELETE_NOTIFICATION,
                notification
            });
        }).catch(err => {

        });
    };
}

const SEND_MESSAGE_API_URL = '/notifications/{GYM_HASH}';

export function sendMessage(subject, message){
    return async function(dispatch, getState){

        const state = getState();
        const gymHash = state.currentGym ? state.currentGym.gym_hash : null;

        if(!gymHash){
            return Promise.reject();
        }

        try{
            const response = await axiosTransport.post(SEND_MESSAGE_API_URL.replace('{GYM_HASH}', gymHash), {
                subject,
                message
            });

            dispatch(setFormErrors(null));
            return dispatch(setFormStatus(FORM_SUCCESS));
        }catch(err){
            dispatch(setFormErrors(err.response.data));
            return dispatch(setFormStatus(FORM_ERROR));
        }
    };
}

const SAVE_DRAFT_NOTIFICATION_API_URL = '/notifications';

export function saveDraftMessage(id, subject, message){

    let payload = {
        id,
        subject,
        message
    };

    return function(dispatch){
        return axiosTransport.post(SAVE_DRAFT_NOTIFICATION_API_URL, payload).then(({data}) => {
            dispatch(setFormErrors(null));
            // return dispatch(setFormStatus(FORM_SUCCESS));

            if(!id && data){
                dispatch(addNotification(data));
            }else{
                dispatch(updateNotification(data));
            }
            return dispatch(loadNotification(data));
        }).catch(err => {
            dispatch(setFormErrors(err.response.data));
            return dispatch(setFormStatus(FORM_ERROR));
        });
    };
}

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export function addNotification(notification){
    return {
        type: ADD_NOTIFICATION,
        notification
    };
}

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

export function updateNotification(notification){
    return {
        type: UPDATE_NOTIFICATION,
        notification
    };
}