import axiosTransport from "./axios";
import { setFormErrors, exitTakePayment } from './';
import { exitAssignDiscount } from './users';
import { setStatusMessage, STATUS_TYPE_SUCCESS } from './forms';

const GET_PLANS_API_URL = '/gyms/plans/';

export function getPlans(){
    return function(dispatch){
        return axiosTransport.get(GET_PLANS_API_URL).then((response) => {

            return dispatch(loadPlans(response.data));
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const LOAD_PLANS = 'LOAD_PLANS';

export function loadPlans(plans){
    return {
        type: LOAD_PLANS,
        plans
    };
};

export const ADD_GYM = 'ADD_GYM';

export function addGym(gym){
    return {
        type: ADD_GYM,
        gym
    };
}

export const SET_CURRENT_GYM = 'SET_CURRENT_GYM';

export function setCurrentGym(gym){
    return {
        type: SET_CURRENT_GYM,
        gym
    };
}

const GET_MEMBERS_API_URL = '/gyms/{GYM_HASH}/members';

export function getMembers(){
    return function(dispatch, getState){

        const { currentGym } = getState();

        if(!currentGym){
            return;
        }

        const getUrl = GET_MEMBERS_API_URL.replace('{GYM_HASH}', currentGym.gym_hash);

        return axiosTransport.get(getUrl).then((response) => {
            return dispatch(loadMembers(response.data));
        }).catch((err) => {
            console.error(err);
        });
    };
}

export const LOAD_MEMBERS = 'LOAD_MEMBERS';

export function loadMembers(members){
    return {
        type: LOAD_MEMBERS,
        members
    };
}

const GET_MEMBERSHIP_TYPES_API_URL = '/gyms/membership-types';

export function getMembershipTypes(){
    return function(dispatch){

        return axiosTransport.get(GET_MEMBERSHIP_TYPES_API_URL).then(({data}) => {
            return dispatch(loadMembershipTypes(data));
        }).catch((err) => {
            console.error(err);
        });
    };
}

export const LOAD_MEMBERSHIP_TYPES = 'LOAD_MEMBERSHIP_TYPES';

export function loadMembershipTypes(types){
    return {
        type: LOAD_MEMBERSHIP_TYPES,
        types
    };
}

const GET_DISCOUNTS_API_URL = '/gyms/discounts';

export function getDiscounts(){
    return function(dispatch){

        return axiosTransport.get(GET_DISCOUNTS_API_URL).then(({data}) => {
            return dispatch(loadDiscounts(data));
        }).catch((err) => {
            console.error(err);
        });
    };
}

export const LOAD_DISCOUNTS = 'LOAD_DISCOUNTS';

export function loadDiscounts(discounts){
    return {
        type: LOAD_DISCOUNTS,
        discounts
    };
}

const CREATE_PAYMENT_API_URL = '/payments';

export function createPayment(userId, paymentMethod, membershipTypeId, startDate){

    let payload = {
        user_id: userId,
        payment_method: paymentMethod,
        membership_type_id: membershipTypeId,
        start_date: startDate
    };

    return function(dispatch){
        return axiosTransport.post(CREATE_PAYMENT_API_URL, payload).then(({data}) => {
            dispatch(exitTakePayment());
            dispatch(setPaymentError());
            dispatch(setStatusMessage('Payment created.', STATUS_TYPE_SUCCESS));
            return dispatch(loadPayment(data));
        }).catch((err) => {
            let { data } = err.response

            if(data && data.message){
                dispatch(setPaymentError(data.message));
            }

            return dispatch(setFormErrors(err.response.data));
        });
    };
}

export const LOAD_PAYMENT = 'LOAD_PAYMENT';

export function loadPayment(payment){
    return {
        type: LOAD_PAYMENT,
        payment
    };
}

export const PAYMENT_ERROR = 'PAYMENT_ERROR';

export function setPaymentError(message){
    return {
        type: PAYMENT_ERROR,
        message
    };
}


/////

const ASSIGN_DISCOUNT_API_URL = '/gyms/assign-discount';

export function assignDiscountToUser(discountId, userId){

    let payload = {
        discount_id: discountId,
        user_id: userId
    };

    return function(dispatch){
        return axiosTransport.post(ASSIGN_DISCOUNT_API_URL, payload).then(({data}) => {
            dispatch(setStatusMessage('Discount assigned.', STATUS_TYPE_SUCCESS));
            return dispatch(exitAssignDiscount());
        }).catch((err) => {
            return dispatch();
        });
    };
}