import {
    LOAD_CHECKINS,
    ADD_CHECKIN,
    CLEAR_LAST_CHECKIN,
    USER_LOGOUT,
    SELECTED_CHECKIN,
    LOAD_PAYMENT,
    CLEAR_CHECKINS,
    MANUAL_CHECKIN,
    EXIT_MANUAL_CHECKIN,
    LOAD_CHECKIN_USERS,
    DELETE_CHECKIN,
    EXIT_DELETE_CHECKIN,
    REMOVE_CHECKIN
} from '../actions';

export function lastCheckin(state = null, action){

    if(ADD_CHECKIN === action.type && action.checkin){
        return action.checkin;
    }

    if(CLEAR_LAST_CHECKIN === action.type){
        return null;
    }

    return state;
}

export function todaysCheckins(state = null, action){

    const { checkins, checkin } = action;
    if([USER_LOGOUT, CLEAR_CHECKINS].includes(action.type)){
        return null;
    }
    
    if (action.type === LOAD_CHECKINS) return checkins;

    if (action.type === ADD_CHECKIN && checkin) {
       
        if (state.date !== checkin.entry_date_only) {
            return state;
        }

        let newState = Object.assign({}, state);
        
        newState.checkins.unshift(checkin);
        return newState;
    }

    if(LOAD_PAYMENT === action.type && state){
        if(!action.payment.dates.includes(state.date)) return state;

        let newState = Object.assign({}, state)
        
        newState.payments = action.payment.payment_totals;
        
        newState.checkins = newState.checkins.map((checkin) => {
            if(checkin.user_id === action.payment.user_id){
                checkin.entry_paid = true;
            }

            return checkin;
        })

        return newState;
    }

    if(REMOVE_CHECKIN === action.type && state){

        let newState = Object.assign({}, state)

        newState.checkins = newState.checkins.map((checkin) => {
            if(checkin.id !== action.id){
                return checkin;
            }

            return undefined;
        }).filter(checkin => checkin !== undefined)

        return newState;
    }

    return state;
}

export function selectedCheckin(state = null, action){

    if(SELECTED_CHECKIN === action.type) return action.checkin;
    
    return state;
}

export function isDoingManualCheckin(state = false, action){

    if(MANUAL_CHECKIN === action.type) return true;

    if(EXIT_MANUAL_CHECKIN === action.type) return false;

    return state;
}

export function isDeletingCheckin(state = false, action){

    if(DELETE_CHECKIN === action.type) return true;
    
    if(EXIT_DELETE_CHECKIN === action.type) return false;
    
    return state;
}

export function checkinUsers(state = [], action){

    if(LOAD_CHECKIN_USERS === action.type) return action.users || []

    return state;
}