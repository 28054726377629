import {
    DRAFT_MESSAGE,
    EXIT_DRAFT_MESSAGE,
    LOAD_NOTIFICATIONS,
    LOAD_NOTIFICATION,
    DELETE_NOTIFICATION,
    ADD_NOTIFICATION,
    UPDATE_NOTIFICATION
} from '../actions';

export function isDraftingMessage(state = false, action){
    
    if(DRAFT_MESSAGE === action.type){
        return true;
    }

    if(EXIT_DRAFT_MESSAGE === action.type){
        return false;
    }

    return state;
}

export function notifications(state = [], action){

    if(LOAD_NOTIFICATIONS === action.type) return action.notifications || state;
    
    if(DELETE_NOTIFICATION === action.type){
        let newState = state.slice(0)
        newState = newState.filter(notif => notif.id !== action.notification.id)

        return newState
    }

    if(UPDATE_NOTIFICATION === action.type){
        let newState = state.slice(0)
        newState = newState.map(notification => {
            if(notification.id === action.notification.id){
                return action.notification;
            }

            return notification;
        })

        return newState
    }

    if(ADD_NOTIFICATION === action.type){
        return [action.notification, ...state]
    }

    return state;
}

export function draftNotification(state = null, action){

    if(LOAD_NOTIFICATION === action.type) return action.notification;

    return state;
}