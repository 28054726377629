import { combineReducers } from 'redux';
import {
    lastCheckin,
    todaysCheckins,
    selectedCheckin,
    isDoingManualCheckin,
    isDeletingCheckin,
    checkinUsers
} from './checkins';
import {
    userCreated,
    currentUser,
    isTakingPayment,
    paymentError,
    isAssigningDiscount,
    isAddingGuestUser,
    isMergingGuestUser,
    fullMembers,
    transferFromGuestStatus,
    member
} from './users';
import {
    currentGym,
    currentGyms,
    plans,
    members,
    membershipTypes,
    discounts
} from './gyms';
import {
    formSuccess,
    formErrors,
    errorMessage,
    currentStatusMessage
} from './forms';
import {
    socket
} from './web_sockets';
import {
    isDraftingMessage,
    notifications,
    draftNotification
} from './notifications';
import {
    payments,
    isDeletingPayment
} from './payments';
import {
    isChangingDate
} from './change_date';

export default combineReducers({
    lastCheckin,
    todaysCheckins,
    selectedCheckin,
    isDoingManualCheckin,
    isDeletingCheckin,
    checkinUsers,
    userCreated,
    currentUser,
    isTakingPayment,
    isAddingGuestUser,
    isMergingGuestUser,
    fullMembers,
    transferFromGuestStatus,
    paymentError,
    isAssigningDiscount,
    currentGym,
    currentGyms,
    plans,
    members,
    membershipTypes,
    discounts,
    formSuccess,
    formErrors,
    errorMessage,
    currentStatusMessage,
    socket,
    isDraftingMessage,
    notifications,
    draftNotification,
    payments,
    isDeletingPayment,
    isChangingDate
});