import {
    SET_USER,
    USER_LOGOUT,
    USER_CREATED,
    TAKE_PAYMENT,
    EXIT_TAKE_PAYMENT,
    PAYMENT_ERROR,
    ASSIGN_DISCOUNT,
    EXIT_ASSIGN_DISCOUNT,
    OPEN_ADD_GUEST_USER,
    EXIT_ADD_GUEST_USER,
    OPEN_MERGE_GUEST_USER,
    EXIT_MERGE_GUEST_USER,
    LOAD_FULL_MEMBERS,
    TRANSFER_SUCCESS,
    TRANSFER_FAILURE,
    RESET_TRANSFER_STATUS,
    SET_TRANSFER_FROM_GUEST_STATUS,
    LOAD_MEMBER
} from '../actions';

const windowGlobal = typeof global.window !== undefined && global.window ? global.window : null;

export function userCreated(state = null, action){

    if(action.type === USER_CREATED){
        return action.user;
    }

    return state;
}

export function currentUser(state = null, action){

    if(!windowGlobal){
        return state;
    }

    let storage = windowGlobal.localStorage;

    if(state === null){
        let user = storage.getItem('user');
        
        if(user){
            return JSON.parse(user);
        }
    }

    if(action.type === USER_LOGOUT){
        storage.clear();
        return null;
    }
    
    if([SET_USER, USER_CREATED].includes(action.type)){
        if(action.user === null){
            storage.clear();
        }

        if(action.user){
            storage.setItem('user', JSON.stringify(action.user));
        }

        if(action.token){
            storage.setItem('token', action.token);
        }

        return action.user;
    }

    return state;
}

export function isTakingPayment(state = false, action){
    
    if(TAKE_PAYMENT === action.type) return true;

    if(EXIT_TAKE_PAYMENT === action.type) return false;

    return state;
}

export function isAddingGuestUser(state = false, action){

    if(OPEN_ADD_GUEST_USER === action.type) return true;

    if(EXIT_ADD_GUEST_USER === action.type) return false;

    return state;
}

export function isMergingGuestUser(state = false, action){

    if(OPEN_MERGE_GUEST_USER === action.type) return true;

    if(EXIT_MERGE_GUEST_USER === action.type) return false;

    return state;
}

export function paymentError(state = null, action){

    if(PAYMENT_ERROR === action.type) return action.message || null;

    return state;
}

export function isAssigningDiscount(state = false, action){

    if(ASSIGN_DISCOUNT === action.type) return true;

    if(EXIT_ASSIGN_DISCOUNT === action.type) return false;

    return state;
}

export function token(state = null, action){

    if(!windowGlobal){
        return state;
    }

    let storage = windowGlobal.localStorage;

    return storage.getItem('token');
}

export function fullMembers(state = [], action){

    if(action.type === LOAD_FULL_MEMBERS){
        state = action.members;
    }

    if(EXIT_MERGE_GUEST_USER === action.type) return [];

    return state;
}

export function transferFromGuestStatus(state = TRANSFER_FAILURE, action){

    if(SET_TRANSFER_FROM_GUEST_STATUS === action.type) return action.status;

    if(RESET_TRANSFER_STATUS === action.type) return TRANSFER_FAILURE;

    return state;
}
