import socketIOClient from 'socket.io-client';

export const WC_CONNECT = 'WC_CONNECT';

export function webSocketConnect(){
    return function(dispatch, getState){
        const state = getState();
        const socket = socketIOClient(process.env.GATSBY_SOCKET_URL);

        if(state.currentGym){
            socket.emit('set_gym', state.currentGym.gym_hash);
        }
        
        return dispatch({
            type: WC_CONNECT,
            socket
        });
    };
}

export const WC_DISCONNECT = 'WC_DISCONNECT';

export function webSocketDisconnect(){
    return function(dispatch, getState){
        const state = getState();

        if(state.socket){
            state.socket.disconnect();
        }

        return dispatch({
            type: WC_DISCONNECT
        });
    };
}