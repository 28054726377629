import axios from 'axios';
import { setFormErrors, setStatusMessage, STATUS_TYPE_SUCCESS } from "./";

export const OPEN_ADD_GUEST_USER = 'OPEN_ADD_GUEST_USER';

export function openAddGuestUser(){
    return {
        type: OPEN_ADD_GUEST_USER
    };
}

export const EXIT_ADD_GUEST_USER = 'EXIT_ADD_GUEST_USER';

export function exitAddGuestUser(){
    return {
        type: EXIT_ADD_GUEST_USER
    };
} 

const CREATE_GUEST_USER_API_URL = '/users/guest';

export const OPEN_MERGE_GUEST_USER = 'OPEN_MERGE_GUEST_USER';

export function openMergeGuestUser(){
    return {
        type: OPEN_MERGE_GUEST_USER
    };
}

export const EXIT_MERGE_GUEST_USER = 'EXIT_MERGE_GUEST_USER';

export function exitMergeGuestUser(){
    return {
        type: EXIT_MERGE_GUEST_USER
    };
}

export function createGuestUser(fullName){
    let payload = {
        full_name: fullName.trim()
    };
    return function(dispatch, getState, api){
        return api.post(CREATE_GUEST_USER_API_URL, payload).then(({data}) => {
            dispatch(setFormErrors(null));
            dispatch(setStatusMessage('Guest user added!', STATUS_TYPE_SUCCESS));
            return dispatch(exitAddGuestUser());
        }).catch(err => {
            return dispatch(setFormErrors(err.response.data));
        });
    };
}

const DELETE_GUEST_USER_API_URL = '/users/guest';

export function deleteGuestUser(id) {
    return function (dispatch, getState, api) {
        return api.delete(`${DELETE_GUEST_USER_API_URL}/${id}`).then(({ data }) => {
            dispatch(setFormErrors(null));
            dispatch(setStatusMessage('Guest user deleted!', STATUS_TYPE_SUCCESS));
        });
    };
}