import { setStatusMessage, STATUS_TYPE_SUCCESS } from './forms';

const GET_PAYMENTS_API = '/payments';

export function getPayments(gymHash, date){

    let payload = {
        date: date.format('YYYY-MM-DD')
    };

    return function(dispatch, getState, getAxios){
        return getAxios.get(GET_PAYMENTS_API, { params: payload}).then((response) => response.data).catch(err => err).then((data) => {
            return dispatch(loadPayments(data));
        });
    };
}

export const LOAD_PAYMENTS = 'LOAD_PAYMENTS';

export function loadPayments(payments){
    return {
        type: LOAD_PAYMENTS,
        payments
    };
};

export const DELETE_PAYMENT = 'DELETE_PAYMENT';

export function deletePayment(){
    return {
        type: DELETE_PAYMENT
    };
}

export const EXIT_DELETE_PAYMENT = 'EXIT_DELETE_PAYMENT';

export function exitDeletePayment(){
    return {
        type: EXIT_DELETE_PAYMENT
    };
}

const DELETE_PAYMENT_API_URL = '/payments/{PAYMENT_ID}';

export function deleteUserPayment(payment){
    return function(dispatch, getState, getAxios){
        return getAxios.delete(DELETE_PAYMENT_API_URL.replace('{PAYMENT_ID}', payment.id)).then(() => {
            dispatch(setStatusMessage('Payment record deleted.', STATUS_TYPE_SUCCESS));
            dispatch(exitDeletePayment());
            return dispatch(removePaymentFromList(payment.id));
        });
    };
}

export const REMOVE_PAYMENT = 'REMOVE_PAYMENT';

export function removePaymentFromList(id){
    return {
        type: REMOVE_PAYMENT,
        id
    };
}

export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS';

export function clearPayments(){
    return {
        type: CLEAR_PAYMENTS
    };
}